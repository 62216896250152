import { obstaclesUnder } from "./buildings";
import { objectProperties, validateAllBehavior } from "./gameObjects";
import {
  featureCollection,
  randomPoint,
  randomPolygon,
  circle,
  booleanWithin,
  intersect,
  bbox,
  bboxPolygon,
  transformRotate,
} from "@turf/turf";

export default function (solution, buildings, objectTypes) {
  return {
    solution: solution,
    boundaries: solution.scenario.site.boundaries,
    bbox: bbox(solution.scenario.site.boundaries),
    objectTypes: objectTypes,
    buildings: buildings,
    groupBbox(center, radius) {
      return bbox(circle(center, radius, { units: "meters", steps: 5 }));
    },
    generateCircle: function (center, radius) {
      return featureCollection([
        circle(center, radius, { units: "meters", steps: 36 }),
      ]);
    },

    generatePoints: function (center, count) {
      let box = this.groupBbox(center, count < 10 ? count : count * 2);
      if (!booleanWithin(bboxPolygon(box), this.boundaries)) {
        box = bbox(this.boundaries);
      }
      const points = randomPoint(count, { bbox: box });

      const features = [];
      for (
        let i = 0;
        i < points.features.length && features.length < count;
        i++
      ) {
        const point = points.features[i];
        if (booleanWithin(point, this.boundaries)) {
          features.push(point);
        }
      }
      return featureCollection(features);
    },

    generatePointsLine: function (center, radius, boundaries, count) {},

    generateLine: function (center, radius, boundaries, count) {},
    generateSquare: function (center, params) {
      let square = bboxPolygon(
        bbox(circle(center, params[0] / 2, { units: "meters", steps: 20 }))
      );
      square = transformRotate(square, this.solution.scenario.rotate || 0, {
        pivot: center,
      });
      return featureCollection([square]);
    },

    generatePolygon: function (center) {
      const features = [];
      const count = 1;

      const polygons = randomPolygon(10, {
        bbox: this.groupBbox(center, 1),
        num_vertices: 4,
        max_radial_length: 0.000225,
      });
      for (
        let i = 0;
        i < polygons.features.length && features.length < count;
        i++
      ) {
        const polygon = polygons.features[i];
        if (polygon.type === "MultiPolygon") {
          continue;
        }
        let is = null;
        try {
          is = intersect(polygon, this.boundaries);
        } catch (err) {
          console.error(err);
          is = null;
        }
        if (is !== null) {
          return featureCollection([is]);
        }
      }
    },

    generateFeatures: function (
      widget,
      type,
      center,
      contextProperties,
      level = 0,
      validFeatures = null
    ) {
      const objectType = this.objectTypes[type];

      let count = 1;
      let features = {};
      const method = objectType.object.method;
      switch (objectType.object.type) {
        case "Polygon":
          if (!method) {
            features = this.generatePolygon(center);
          }
          if (method === "rectangle") {
            features = this.generateSquare(center, objectType.object.params);
          }
          if (method === "circle") {
            features = this.generateCircle(center, objectType.object.radius);
          }
          break;
        case "LineString":
          features = featureCollection(this.generateLine(center));
          break;

        case "Point":
          count = objectType.object.count;
          if (!method) {
            features = this.generatePoints(center, count);
          } else if (method === "circle") {
            features = featureCollection(
              this.generateCircle(center, objectType.object.radius)
            );
          } else if (method === "line") {
            features = this.generatePointsLine(center, count);
          }
          break;
      }

      const randomInt = function (min, max) {
        return min + Math.floor((max - min) * Math.random());
      };

      features.features = features.features.map((f) => {
        f.properties = objectProperties(objectType, contextProperties);
        const id = parseInt(
          new Date().getTime() + randomInt(0, 10000000).toString()
        );
        f.properties.id = id;
        return f;
      });

      const obstacles = obstaclesUnder(widget.map, features);
      const all = featureCollection(
        this.solution.implementations.features.concat(features.features)
      );
      const invalidFeatures = validateAllBehavior(
        features,
        all,
        this.buildings,
        obstacles,
        this.objectTypes
      );
      if (!validFeatures) validFeatures = featureCollection([]);
      features.features.forEach((f) => {
        if (
          Object.keys(invalidFeatures).indexOf(f.properties.id.toString()) == -1
        ) {
          validFeatures.features.push(f);
        }
      });
      const validCount = Object.keys(validFeatures.features).length;
      if (validCount == count) {
        return validFeatures;
      }
      if (validCount > count) {
        return featureCollection(validFeatures.features.slice(0, count));
      } else if (level < 5) {
        level += 1;
        return this.generateFeatures(
          widget,
          type,
          center,
          contextProperties,
          level,
          validFeatures
        );
      } else {
        return false;
      }
    },
  };
}
