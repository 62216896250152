<template>
  <div class="game">
    <div v-if="warning" id="error" class="error">
      {{ warning }}
    </div>

    <div v-if="multiplayer" id="status" class="status">
      <a v-if="!gameConnected" @mousedown="reconnect">Game offline. Click here to reconnect</a>
      <!-- {{ status }} -->
    </div>

    <div v-if="info" id="info" class="warning" v-html="info">
    </div>

    <div id="admin-debug" v-if="currentUser().customData.admin">
      <h3>NBS values</h3>
      <div>(you only see this as an admin)</div>
      <div><b>Total area: </b>{{ totalArea }} m2</div>
      <div v-if="evaluation">
        <div v-for="(name, id) in nbsLabels" v-bind:key="id">
          <div v-if="evaluation.deployedNbs()[id]">
            <b>{{ name }}</b
            >: {{ evaluation.deployedNbs()[id].size }}
            {{ evaluation.deployedNbs()[id].unit }}
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar">
      <div class="challenge">
        <a
          class="startOver"
          href="#"
          @mousedown.prevent="back"
          :title="backTitle"
        >
          <img src="/images/layout/arrow.png" width="14" height="14" />
        </a>
        <strong class="challenge-label">{{
          nbsSelected
            ? mapObjectTypes[nbsSelected].name
            : $store.state.solution.scenario.title
        }}</strong>
      </div>
      <nbs-details
        :nbsType="mapObjectTypes[nbsSelected]"
        :mapModeActive="!!mapMode"
        :budget="budgetLeft"
        v-if="nbsSelected"
        @buy="selectNbs"
        @cancel="cancelNbs"
      ></nbs-details>

      <div id="nbs-options" v-if="!nbsSelected">
        <div
          v-for="(parentTypes, label) in this.groupedNbsOptions"
          v-bind:key="label"
          class="nbs-group"
        >
          <h4 class="group-label">{{ label }}</h4>

          <div
            v-for="(types, parentType) in parentTypes"
            v-bind:key="parentType"
            class="nbs-type"
          >
            <div class="header">
              <a
                @mousedown.prevent="toggleParentNbsWidget(parentType)"
                class="pointer nbs-label parent"
                >{{ parentType }}</a
              >

              <a
                @mousedown.prevent="toggleParentNbsWidget(parentType)"
                class="pointer nbs-label"
                v-if="!(openParentType == parentType)"
              >
                <img src="/images/ui/chevron.png" />
                <img
                  v-if="openParentType == parentType"
                  src="/images/ui/chevron-up.png"
                />
              </a>
            </div>
            <div v-if="openParentType == parentType">
              <div
                class="nbs-subtype"
                v-for="type in types"
                v-bind:key="type._id"
              >
                <div class="header">
                  <a
                    href="#"
                    @mousedown.prevent="openNbsWidget(type)"
                    class="pointer nbs-label"
                    >{{ type.name }}</a
                  >
                  <span
                    ><img
                      v-if="mapHasNbs(type)"
                      class="nbs-label"
                      src="/images/layout/nbs-arrow.png"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="map-wrapper">
      <map-widget
        :width="this.window.width - 300"
        :height="this.window.height"
        ref="map"
        :id="mapId"
        :boundaries="boundaries"
        :defaultZoom="siteZoom"
        :editable="true"
        :objects="editableImplementations"
        :displayOnly="displayOnlyImplementations"
        :existing="existing"
        :additionalProperties="globalMapProperties"
        :objectTypes="mapObjectTypes"
        :rotate="rotate"
        :predefined="predefined"
        :generator="generator"
        :showDisplayOnlyIcons="year >= timelineMax"
        @input="saveImplementation"
      />

      <div id="impacts-bar" :class="showEvaluation ? 'open' : 'closed'" >
        <div class="header" @mousedown="toggleEvaluation">
          <h2><span class="year">{{ year }}-{{ nextPeriod }}</span></h2>
          <div class="period">

          </div>

          <!-- <a
                @mousedown.prevent="toggleParentNbsWidget(parentType)"
                class="pointer nbs-label"
                v-if="!(openParentType == parentType)"
          > -->
          <span class="chevron">
            <img v-if="showEvaluation" src="/images/ui/chevron.png" />
            <img v-if="!showEvaluation" src="/images/ui/chevron-up.png" />
          </span>
            <!-- <img
              v-if="openParentType == parentType"
              src="/images/ui/chevron-up.png"
            /> -->

        </div>
        <div class="budget progress-bar-widget">
          <div class="label">Available budget</div>
          <div class="numbers">
            <div class="value">{{ budgetLeft }} €</div>
            <div class="max">{{ initialBudget }}</div>
          </div>
          <div class="progress-wrapper">
            <div
              class="progress-bar"
              v-bind:style="budgetLineStyle"
              v-bind:key="budgetLine"
            />
          </div>
        </div>

        <div
          v-if="showEvaluation && evaluation && evaluation.indicators"
          class="evaluation"
        >
          <div class="charts" v-if="displayedIndicators.length <= 3">
            <h4 class="label">Your impact</h4>
            <div
              :class="'chart-wrapper ' + indicator"
              v-for="(indicator, index) in displayedIndicators"
              v-bind:key="index"
            >
              <div class="indicator-head">
                <strong class="indicator">{{
                  evaluation.indicators[indicator].name
                }}</strong>
                <strong class="indicator-value">{{
                  evaluation.evaluation(indicator, year)
                }}</strong>
                <span
                  class="indicator-unit"
                  v-if="
                    evaluation.indicators[indicator].unit &&
                    evaluation.indicators[indicator].unit.length <= 5
                  "
                >
                  {{ evaluation.indicators[indicator].unit }}</span
                >
              </div>
              <line-chart
                :class="'chart'"
                :ref="'chart' + indicator"
                :chart-data="
                  evaluation.datacollection(
                    indicator,
                    evaluation.baselineDatacollection(indicator)
                  )
                "
                :options="evaluation.chartOptions(indicator)"
                :width="280"
                :height="120"
              >
              </line-chart>
            </div>
          </div>

          <div class="charts condensed" v-if="displayedIndicators.length > 3">
            <div class="label">Distance to target</div>
            <div
              :class="'chart-wrapper ' + indicator"
              v-for="(indicator, index) in displayedIndicators"
              v-bind:key="index"
            >
              <div class="indicator-head">
                <strong
                  >{{ evaluation.resultPercentage(indicator, year) }} %</strong
                >
                <div class="label">
                  {{ evaluation.indicators[indicator].name }}
                </div>
              </div>
            </div>
          </div>

          <div class="label">Stakeholder satisfaction</div>
          <div
            class="avg-satisfaction progress-bar-widget"
            v-bind:key="satisfactionKey"
          >
            <div class="numbers">
              <div class="value">{{ evaluation.satisfaction() }}</div>
              <div class="max">5</div>
            </div>
            <div class="progress-wrapper">
              <div class="progress-bar" v-bind:style="indicatorLineStyle" />
            </div>
          </div>

          <div
            v-if="economicScore && evaluation"
            :class="'economic-score progress-bar-widget'"
          >
            <div class="label">
              {{ evaluation.indicators[economicScore].name }}
            </div>
            <div class="numbers">
              <div class="value">
                {{ evaluation.evaluation(economicScore, year) }}
                <span
                  class="unit"
                  v-if="
                    evaluation.indicators[economicScore].unit &&
                    evaluation.indicators[economicScore].unit.length <= 5
                  "
                >
                  {{ evaluation.indicators[economicScore].unit }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="overall-score" v-if="evaluation">
          <h3>Overall score</h3>
          <div>
            <strong class="value">{{ evaluation.overallScore() }}</strong> / 100
          </div>
        </div>
        <div class="buttons">
          <button
            v-if="nextPeriod < timelineMax"
            @mousedown.prevent="next"
            class="wide"
            variant="primary"
          >
            Next period
          </button>
          <button
            v-if="nextPeriod >= timelineMax"
            @mousedown.prevent="next"
            class="wide"
            variant="success"
          >
            Finish your plan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapWidget from "../components/MapWidget";
import NbsDetails from "../components/GameNbsDetails";

import * as turf from "@turf/turf";
import LineChart from "../components/lineChart";
import {
  removeOverlaps,
  removeDupes,
  cropOutOfBounds,
  validateAllBehavior,
} from "../gameObjects";
import { zoomToFeature } from "../mapHelpers";
import { loadBuildings, setBuildings, obstaclesUnder } from "../buildings";
import { currentUser } from "../store";
import Budget from "../budget";
import Generator from "../generator";
import Evaluation from "../evaluation";

import {
  addPredefinedObject,
  addBuildingObject,
  setDrawMode,
  generateObjects,
} from "../mapObjectTypes";

export default {
  name: "ImplementationGame",
  components: {
    MapWidget,
    LineChart,
    NbsDetails,
  },
  data: function() {
    return {
      count: null,
      rotate: 0,
      categories: [],
      timelineMax: 0,
      timelineStep: 5,
      boundaries: null,
      mapId: "map-implementation",
      mapObjectTypes: {},
      siteZoom: 13,
      chartHeight: 120,
      indicators: [],
      economicScore: null,
      existing: { type: "FeatureCollection", features: [] },
      implementation: { type: "FeatureCollection", features: [] },
      year: parseInt(this.$store.state.solution.timeline), // Hack, todo remove this
      initialBudget: 0,
      budget: null,
      budgetLeft: null,
      evaluation: null,
      warning: null,
      predefined: { type: "FeatureCollection", features: [] },
      buildings: { type: "FeatureCollection", features: [] },
      itemSelected: false,
      generator: null,
      window: {
        width: 0,
        height: 0,
      },
      budgetLine: 0,
      groupedNbsOptions: {
        "Nature-Based Solutions": {},
        Amenities: {},
      },
      amenities: [],
      showEvaluation: true,
      nextPeriod: null,
      openParentType: null,
      nbsSelected: null,
      info: "",
      satisfactionKey: 0,
      status: "",
      multiplayer: false
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.loadMapObjectTypes();

    const load = async (nbsTypes) => {
      let solution;
      if (this.$store.state.code) {
        this.multiplayer = true
        try {
          solution = await this.$store.dispatch('loadSolutionByCode', this.$store.state.code)
          await this.joinOrCreate(solution.room, solution.code)
          solution = await this.$store.dispatch('loadSolution', solution._id.toString())
        }
        catch(e) {
          this.warning = "Cannot load solution data: " + e.message
        }
      } else {
        solution = this.$store.state.solution
        solution = await this.$store.dispatch('loadSolution', solution._id.toString())
        this.solution = solution
      }

      this.mapId = this.mapId + this.$store.state.solution._id.toString()
      this.predefined = this.$store.state.solution.scenario.predefined ||
      this.$store.state.solution.scenario.site.predefined
      this.rotate = this.$store.state.solution.scenario.rotate || 0
      this.year = this.$store.state.solution.timeline

        // Load state if connected to game server
        if (this.gameConnected) {
          this.warning = ""
          this.implementation = this.addNbsProps(this.gameState)
        }
        // Or load state from database.
        else {
          this.status = "Offline."
  //        this.implementation = this.$store.state.solution.implementations
        }

      // Init game objects

        this.evaluation = new Evaluation(
          solution,
          nbsTypes,
          this.$store.state.currentProject.indicators
        );
        if (
          solution.scenario.keyIndicators &&
          solution.scenario.keyIndicators.length > 0
        ) {
          this.displayedIndicators = solution.scenario.keyIndicators;
        } else {
          this.displayedIndicators = Object.values(solution.scenario.indicators)
            .filter((i) => {
              return !this.evaluation.indicators[i._id].economicScore;
            })
            .map((i) => {
              return i._id;
            });
        }

        const displayed = turf.featureCollection([]);
        if (this.$store.state.solution.scenario.site.trees) {
          this.$store.state.solution.scenario.site.trees.features.forEach((t) => {
            t.properties.objectType = "tree";
            displayed.features.push(t);
          });
        }

        this.existing = displayed;

        const economicScore = Object.values(this.evaluation.indicators).find(
          (i) => {
            return i.economicScore;
          }
        );
        if (economicScore) this.economicScore = economicScore._id;
        this.budget = new Budget(this.$store.state.solution, nbsTypes);

        this.siteZoom = solution.scenario.site.zoomSmall || 13;
        this.timelineMax =
          this.evaluation.years[this.evaluation.years.length - 1];
        this.timelineStep = solution.scenario.timelineStep;

        if (solution.timeline >= this.timelineMax) {
          solution.timeline = this.timelineStep
            ? this.timelineMax - this.timelineStep
            : solution.scenario.startYear;
          this.evaluation.year = solution.timeline;
        }

        this.year = parseInt(solution.timeline);

        this.nextPeriod = this.timelineStep
          ? this.year + this.timelineStep
          : this.timelineMax;
        if (this.timelineStep && solution.timeline >= this.timelineMax) {
          solution.timeline = this.timelineMax - this.timelineStep;
        }

        for (const k in nbsTypes) {
          if (
            nbsTypes.hasOwnProperty(k) &&
            nbsTypes[k].object &&
            solution.scenario.nbsTypes &&
            solution.scenario.nbsTypes.indexOf(k) > -1
          ) {
            const type = nbsTypes[k];
            const key = type.amenity ? "Amenities" : "Nature-Based Solutions";

            let c = { name: "Other" };

            if (type.category || type.nbsCategory) {
              const cid = type.category || type.nbsCategory;
              c = this.categories[cid];
              if (type.subCategory) {
                let subc = this.categories[cid].subtypes.find((s) => {
                  return s._id == type.subCategory;
                });
                if (subc) c = subc
              }
            }
            if (!this.groupedNbsOptions[key][c.name]) {
              this.groupedNbsOptions[key][c.name] = [];
            }
            this.groupedNbsOptions[key][c.name].push(type);
          }
        }

        this.generator = new Generator(
          solution,
          this.buildings,
          this.mapObjectTypes
        );
        this.initialBudget = this.budget.initial();
        this.updateBudget();
    };

    this.$store.dispatch("loadNbsCategories").then(() => {
      this.categories = this.$store.state.nbsCategories;
      load(this.$store.state.currentProject.nbsTypes);
    });
  },
  mounted: function() {

    this.$nextTick(() => {
      this.$refs.map.map.on("load", () => {
//        this.loadMapObjectTypes();
        this.$refs.map.loadIcons().then(() => {
          this.$refs.map.addObjectModes();
          this.$refs.map.addDraw();

          this.boundaries = turf.lineToPolygon(
            turf.lineString(
              this.$store.state.scenario.site.boundaries.geometry.coordinates[0]
            ),
            { autoComplete: true }
          );

          if (this.$store.state.solution.scenario.site.buildings) {
            this.buildings = this.$store.state.solution.scenario.site.buildings;
            setBuildings(this.$refs.map.map, this.buildings);
          } else {
            loadBuildings(this.$refs.map.map, this.boundaries, this.buildings);
          }
          zoomToFeature(this.$refs.map.map, this.boundaries);
        });
      });
    });
  },
  computed: {
    gameState() {
      return this.$game.state.implementations;
    },
    gameConnected() {
      return this.$game.state.connected;
    },
    backTitle() {
      return this.nbsSelected ? "Back to NBS menu" : "Back to scenario details";
    },
    mapMode() {
      return this.$refs.map.modeSelected;
    },
    budgetLineStyle() {
      const b = this.budgetLine;
      const d = { width: `${b}%` };
      if (b > 60) {
        d["border-color"] = "#33AC5F";
      } else if (b < 25) {
        d["border-color"] = "#EB621C";
      } else {
        d["border-color"] = "#F2CA30";
      }
      return d;
    },
    indicatorLineStyle() {
      const b = (this.evaluation.satisfaction() / 5) * 100;

      const d = { width: `${b}%` };
      if (b > 60) {
        d["border-color"] = "#33AC5F";
      } else if (b < 25) {
        d["border-color"] = "#EB621C";
      } else {
        d["border-color"] = "#F2CA30";
      }
      return d;
    },

    editableImplementations() {
      const features = this.implementation.features.filter((item) => {
        return (item.properties.timeline || 0) == parseInt(this.year);
      });
      return { type: "FeatureCollection", features: features };
    },
    displayOnlyImplementations() {
      const features = this.implementation.features.filter((item) => {
        return (item.properties.timeline || 0) < parseInt(this.year);
      });
      return { type: "FeatureCollection", features: features };
    },
    globalMapProperties() {
      return {
        timeline: this.year,
      };
    },
    totalArea() {
      if (this.boundaries) {
        return Math.floor(turf.area(this.boundaries));
      } else return null;
    },
    nbsLabels() {
      const types = {};
      for (const type in this.$store.state.currentProject.nbsTypes) {
        types[type] = this.$store.state.currentProject.nbsTypes[type].name;
      }
      return types;
    },
  },
  watch: {
    budgetLeft() {
      this.budgetLine = (this.budgetLeft / this.initialBudget) * 100;
    },
    gameState() {
      this.implementation = this.addNbsProps(this.gameState);
      this.$store.state.solution.implementations = this.implementation
    },
    gameConnected(value) {
      if (!value) {
        function delay(t, val) {
          return new Promise(resolve => setTimeout(resolve, t, val));
        }
        delay(5000).then(async () => {
          const solution = await this.$store.dispatch(
            'loadSolutionByCode', this.$store.state.code)

          this.joinOrCreate(solution.room, solution.code).then(() => {
            this.warning = ""
          })
        })
      } else {
        this.warning = ""
      }
    },
  },
  methods: {
     async reconnect() {
        const solution = await this.$store.dispatch('loadSolutionByCode', this.$store.state.code)
        await this.joinOrCreate(solution.room, solution.code)
     },
     async joinOrCreate (room, code)  {
      const roomId = await this.$game.joinOrCreateRoom(room, {code: code})
      this.$store.commit("setRoomId", roomId);
    },
    addNbsProps(coll) {
      const processed = coll.features.map((f) => {
        f.properties = Object.assign({}, f.properties, this.mapObjectTypes[f.properties.objectType].object.options)
        return f
      })
      return turf.featureCollection(processed)
    },
    toggleEvaluation() {
      this.showEvaluation = !this.showEvaluation;
    },
    mapHasNbs(type) {
      const features = this.implementation.features.filter((f) => {
        return f.properties.objectType == type._id;
      });
      return features.length > 0;
    },
    nbsActive(type) {
      return this.nbsSelected == type._id;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight > 860 ? window.innerHeight : 860;
    },

    currentUser() {
      return currentUser();
    },
    // Load NBS Type definitions and load them into a place where the map can use it.
    loadMapObjectTypes() {
      const load = (nbsTypes) => {
        for (const k in nbsTypes) {
          if (
            nbsTypes.hasOwnProperty(k) &&
            nbsTypes[k].object &&
            this.$store.state.scenario.nbsTypes &&
            this.$store.state.scenario.nbsTypes.indexOf(k) > -1
          ) {
            this.mapObjectTypes[k] = nbsTypes[k];
          }
        }
      };

      load(this.$store.state.currentProject.nbsTypes);
    },
    updateBudget() {
      const budget = this.budget ? this.budget.calculate() : undefined;
      this.budgetLeft = budget < 0 ? 0 : budget;
    },

    openNbsWidget(type) {
      if (this.mapMode) return;
      if (!this.nbsSelected || this.nbsSelected != type._id) {
        this.nbsSelected = type._id;
        this.currentType = type;
      } else {
        this.nbsSelected = null;
        this.currentType = null;
        this.count = null;
      }
    },

    toggleParentNbsWidget(parentType) {
      if (this.openParentType == parentType) {
        this.openParentType = null;
      } else {
        this.openParentType = parentType;
      }
    },

    selectNbs(type) {
      //			let type = this.mapObjectTypes[type_id]
      this.warning = "";
      if (type.object.predefined) {
        this.info =
          "Select the areas where you place the " + type.name.toLowerCase();
        addPredefinedObject(this.$refs.map, type);
      } else if (type.behavior && type.behavior.buildings) {
        this.info =
          "Select buildings where you place the " + type.name.toLowerCase();
        addBuildingObject(this.$refs.map, type);
      } else if (!type.object.fixed) {
        this.info =
          "Draw on the map or click to create polygons (press enter to finalize shape).";
        setDrawMode(this.$refs.map, type);
      } else {
        generateObjects(this.$refs.map, type);
      }
    },

    cancelNbs() {
      this.$refs.map.resetListener();
      this.$refs.map.modeSelected = false;
      this.nbsSelected = false;
      this.info = "";
    },

    // Remove objects which are outside of the allowed area.
    validate(newObjects, objects) {
      const obstacles = obstaclesUnder(this.$refs.map.map, newObjects);
      const invalid = validateAllBehavior(
        newObjects,
        objects,
        this.buildings,
        obstacles,
        this.mapObjectTypes
      );
      if (Object.values(invalid).length > 0) {
        const error = Object.values(invalid)[0][0];

        switch (error) {
          case "otherObject":
            this.warning = "These items cannot be put on each other.";
            break;
          case "building":
            this.warning = "You cannot put this item on a building.";
            break;
          case "obstacle":
            this.warning = "You cannot put elements on roads and water.";
            break;
        }
        this.cancelNbs();
        return false;
      }
      return true;
    },
    unifyAndCrop(objects) {
      const cropped = cropOutOfBounds(objects, this.boundaries);
      const noOverlaps = removeOverlaps(cropped);
      const noDupes = removeDupes(noOverlaps);

      return noDupes;
    },
    featureConcat(coll1, coll2) {
      return turf.featureCollection(coll1.features.concat(coll2.features));
    },
    saveImplementation(newObjects, objects, prevState, event) {
      if (!this.mapMode) {
        this.cancelNbs();
      }
      if (this.year >= this.timelineMax) {
        this.implementation = prevState;
        this.cancelNbs();
        return;
      }
      if (event === "error_predefined") {
        this.warning = "You cannot move or edit a predefined solution";
        this.implementation = prevState;
        this.cancelNbs();
        return;
      }
      if (event === "error_not_editable") {
        this.warning = "This solution is not resizable";
        this.implementation = prevState;
        this.cancelNbs();
        return;
      }
      this.warning = null;
      if (
        (this.budget && this.budget.calculate(objects.features) > 0) ||
        event == "delete"
      ) {
        let implementation = {};
        let solution = this.$store.state.solution
        if (event !== "delete") {
          const allObjects = this.featureConcat(
            objects,
            this.displayOnlyImplementations
          );
          const validObjects = this.validate(newObjects, allObjects);
          if (validObjects) {
            const cropped = this.unifyAndCrop(objects);
            const features = this.featureConcat(
              cropped,
              this.displayOnlyImplementations
            );
            implementation = features;
            //solution.implementations = features;
          } else {
            implementation = this.featureConcat(
              prevState,
              this.displayOnlyImplementations
            );
          }
        } else {
          implementation = this.featureConcat(
            objects,
            this.displayOnlyImplementations
          );
        }


        // Single player
        if (solution.owner) {
          this.implementation = implementation
          solution.implementations = this.implementation
          this.updateBudget();
          this.$store
          .dispatch("saveSolution", solution)
          .then(() => {
            this.$store.commit("setSolution", solution);
          })
          .catch((e) => {
            console.log(e);
          });
        }
        else if (this.gameConnected) {
          console.log("Game connected. sending message.")
          this.$game.setImplementation(implementation).then(() => {
            this.implementation = implementation
            solution.implementations = this.implementation
            this.$store.commit("setSolution", solution);
            this.updateBudget();
          });
        }

        // this.$store
        //   .dispatch("saveSolution", solution)
        //   .then(() => {
        //     this.$store.commit("setSolution", solution);
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      } else {
        this.warning = "You have run out of budget with this action";
        this.implementation = this.featureConcat(
          prevState,
          this.displayOnlyImplementations
        );
        this.updateBudget();
        this.cancelNbs();
      }
      this.satisfactionKey += 1;
    },
    next() {
      this.cancelNbs();
      this.warning = "";

      const solution = this.$store.state.solution;
      if (!solution.budgetUsed) {
        solution.budgetUsed = {};
      }
      solution.budgetUsed[this.year.toString()] =
        this.$store.state.scenario.budgetPerPhase - this.budget.calculate();
      solution.timeline = this.timelineStep
        ? solution.timeline + this.timelineStep
        : this.timelineMax;

      if (this.$store.state.solution.timeline < this.timelineMax) {
        this.initialBudget = this.budget.initial();
        this.budgetLeft = this.budget.calculate();
        this.evaluation.year = solution.timeline;
        this.year = solution.timeline;
      }

      solution.implementations = this.implementation

      if (solution.owner) {
        this.$store.dispatch("saveSolution", solution).then(() => {
          this.$store.commit("setSolution", solution);
          if (this.$store.state.solution.timeline >= this.timelineMax) {
            this.$router.push({
              path: "/Evaluation",
            });
          }
        });
      } else {
        this.$game.finishGame().then(() => {
          this.$router.push({
            path: "/Evaluation",
          });
        })
      }


    },
    back() {
      if (!this.nbsSelected) {
        this.$store.commit("setCurrentPage", "/ExploreSite");
        this.$router.push({
          path: "/ExploreSite",
        });
      } else {
        this.cancelNbs();
        this.nbsSelected = false;
      }
    },
  },
};
</script>

<style lang="scss">
.progress-wrapper {
  background: rgba(134, 134, 134, 0.1);
  line-height: 0;
  padding: 0;
  margin: 0;
  height: 4px;
}

.progress-bar {
  border-top: solid 4px;
  display: inline-block;
  height: 0;
}
.progress-bar-widget {
  .max {
    font-size: 10px;
    line-height: 10px;
    height: 10px;
    color: #868686;
  }
  .numbers {
    display: flex;
    justify-content: space-between;
    margin: 0;
    flex-basis: 10px;
    align-items: baseline;
    margin-bottom: 12px;
    .value {
      font-weight: bold;
    }
  }
}


.game .label {
  color: #2b4d37;
  margin-bottom: 4px;
}
.warning {
  height: 44px;

  background: #f2d039;
  border-radius: 10px;
}
.budget-bar {
  border-top: solid 4px;
  height: 0;
  max-height: 0px;
  display: inline-block;
}
#sidebar {
  position: fixed;
  top: 0px;
  bottom: 0;
  height: 100%;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1005;
  background: #fff;
  overflow: auto;
}

#sidebar .challenge {
  padding: 15px;
  height: 40px;

  align-items: center;
  display: flex;

  background: url("/images/background/header_yellow.jpg");
}

.startOver {
  display: inline-block;
  font-size: 18px;
  margin-right: 37px;
  color: black;
}
.startOver:hover {
  text-decoration: none;
}

.group-label {
  @include futura-bold;
  font-size: 13px;
  line-height: 134.19%;
  /* or 17px */

  text-transform: uppercase;

  /* copy / dark */

  color: #2b4d37;
  margin: 15px 0 20px 0;
}

#sidebar #nbs-options,
#sidebar > .nbs-details {
  margin: 20px;
}

#sidebar > .nbs-details img {
  width: 100%;
}

.nbs-group:not(:last-child) {
  margin-bottom: 0px;
  padding-bottom: 10px;
  border-bottom: solid #eee 1px;
}

.nbs-type {
  margin-bottom: 8px;
}
.nbs-type .header {
  display: flex;
  justify-content: space-between;
}

.nbs-type .header > span {
  min-width: 16px;
}

.nbs-subtype {
  margin-left: 20px;
}

.nbs-subtype:first-child {
  margin-top: 8px;
}

#sidebar .nbs-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(51, 172, 95, 0.1);
  border-radius: 10px;
  height: 40px;
}

#sidebar .challenge-label {
  @include futura-bold;
  font-size: 18px;
  line-height: 134.19%;
}
#sidebar .nbs-label {
  @include futura-normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* copy / dark */

  color: #2b4d37;

  display: inline-block;
  margin: 2px 0;
}
#sidebar .nbs-label.parent {
  font-weight: 500;
}
#sidebar .active .nbs-label {
  color: #2780ba;
}

#impacts-bar .header {
  position: relative;
}
#impacts-bar .chevron {
    position: absolute;
    top: 23px;
    right: 20px;
}

.plus,
.minus {
  background: #33ac5f;
  color: white;
  border-radius: 20px 10px 10px 20px;
  line-height: 38px;
  font-size: 38px;
  width: 47px;
  display: inline-block;
  text-align: center;
  height: 38px;
  vertical-align: top;
}
.minus {
  line-height: 30px;
  border-radius: 10px 20px 20px 10px;
}
.count {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #33ac5f;
}

.plus.inactive,
.minus.inactive {
  background: rgba(43, 77, 55, 0.25);
}

#admin-debug {
  background: white;
  position: absolute;
  z-index: 1010;
  bottom: 0;
  left: 350px;
  padding: 20px;
}

.game #map-wrapper .mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 300px;
  z-index: 1001;
  height: inherit;
}

.game .charts {
  margin-top: 20px;
}
.game .chart {
  position: relative;
  margin-left: -45px;
  margin-bottom: -14px;
}

.overall-score {
  background: rgba(242, 208, 57, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 25px;
  flex: 1 1;
  h3 {
    @include futura-bold;
    font-size: 13px;
    line-height: 24px;
    /* or 185% */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* copy / dark */

    color: #2b4d37;
  }
  .score {
    @include futura-bold;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    /* copy / dark */

    color: #2b4d37;
  }
}

#impacts-bar .economic-score,
#impacts-bar .avg-satisfaction {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: solid #eee 1px;
}

.game .peopleVisiting .chart:before {
  width: 195px;
}

.game .peopleFrequency .chart:before {
  width: 223px;
}

.chart canvas {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
}

.game .chart:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 215px;
  height: 72px;
  opacity: 0.7;
  z-index: 100;
  background: rgba(235, 98, 28, 0.1);
  border-radius: 10px;
}

#impacts-bar {
  position: absolute;
  width: 280px;

  right: 0px;
  top: 0px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  z-index: 1002;
}

#impacts-bar.closed {
  top: auto;
  bottom: 0px;
}

#impacts-bar:hover {
  cursor: pointer;
}

#impacts-bar > div {
  margin: 0 30px;
}

#impacts-bar > h4 {
  margin: 0 30px 0px 30px;
}

#impacts-bar .buttons {
  margin: 30px;
}

#impacts-bar .header {
  margin: 0 0 20px 0;
  padding: 20px 0;
  background: url("/images/background/header_yellow.jpg");
}
#impacts-bar h2 {
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  text-align: center;
  text-transform: uppercase;
}

#impacts-bar .period {
  @include futura-normal;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.big-bold {
  @include futura-bold;
  font-size: 36px;
  line-height: 134.19%;
  /* or 48px */

  text-transform: uppercase;
}

.condensed .indicator-head {
  align-items: flex-start;
}
.condensed .indicator-head strong {
  min-width: 60px;
}
.condensed .indicator-head .label {
  text-align: right;
}

.indicator-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: flex-end;
}
.indicator {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  flex-basis: 160px;
}

.indicator-value,
.indicator-unit {
  font-size: 24px;
  line-height: 134.19%;
  /* or 32px */

  text-align: right;
  color: #2b4d37;
}
.indicator-value {
  text-transform: uppercase;
}
.indicator-unit {
  font-size: 14px;
  line-height: 28px;
}

#impacts-bar button {
  width: 100%;
}

.game {

  #error,
  #info, #status {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    right: 0;
    margin: auto;
    width: inherit;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 25px
  }

  #info {
    z-index: 1003;
  }

  #error {
    background: red;
    z-index: 1004;
  }

  #status {
    color: grey;
    z-index: 1004;
    position: absolute;
    cursor: pointer;
  }
}

</style>
