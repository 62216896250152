import nbsCalculator from "./nbs";

export default function (solution, objectTypes) {
  const budget = {
    solution: solution,
    objectTypes: objectTypes,
    year() {
      return this.solution.timeline;
    },
    periodsSince() {
      if (!this.solution.scenario.timelineStep) return 0;
      return Math.floor(
        (this.year() - this.solution.scenario.startYear) /
          (this.solution.scenario.timelineStep || 5)
      );
    },
    initial() {
      let totalBudget = 0;

      totalBudget =
        parseInt(this.solution.scenario.budgetPerPhase) +
        parseInt(this.solution.scenario.budgetPerPhase) * this.periodsSince();

      let budgetSpentBefore = 0;

      if (this.solution.scenario.timelineStep) {
        for (
          let y = this.solution.scenario.startYear;
          y <= this.year();
          y += this.solution.scenario.timelineStep || 5
        ) {
          if (
            this.solution.budgetUsed &&
            this.solution.budgetUsed[y.toString()]
          ) {
            budgetSpentBefore += this.solution.budgetUsed[y.toString()];
          }
        }
      }
      return totalBudget - budgetSpentBefore;
    },
    calculate: function (features) {
      features = features || this.solution.implementations.features;

      let budgetSpent = 0;

      features.forEach((f) => {
        const type = this.objectTypes[f.properties.objectType];
        if (!type) return;

        const size = this.nbsSize(f);
        if (f.properties.timeline == this.year()) {
          budgetSpent += type.cost.base * size;
        }

        // if (f.properties.timeline < this.year() && type.cost.maintenance && type.cost.maintenance.type === 'percentage') {
        // 	budgetSpent += (type.cost.base * size * type.cost.maintenance.value / 100) * this.periodsSince()
        // }
      });
      return Math.floor(this.initial() - budgetSpent);
    },
  };

  return Object.assign(budget, nbsCalculator);
}
