<template>
  <div class="nbs-details">
    <div class="image">
      <img :src="'/images/nbs/' + nbsType._id + '.jpg'" />
    </div>
    <div class="description">{{ nbsType.description }}</div>
    <div class="cost">
      <div class="text">Cost per {{ nbsType.unit || "unit" }}:</div>
      <div class="value">{{ nbsType.cost.base }} {{ currency }}</div>
    </div>

    <div v-if="nbsType.cost.maintenance" class="cost">
      <div class="text">Maintenance cost per {{ nbsType.unit || "unit" }}</div>
      <div class="value">
        {{ nbsType.cost.maintenance.value }} {{ currency }}
      </div>
    </div>

    <div class="nbs-count" v-if="nbsType.type == 'Point'">
      <a
        href="#"
        :class="count == 1 ? 'minus inactive' : 'minus'"
        @mousedown.prevent="setCount(count - 1)"
        >–</a
      >
      <span class="count" contenteditable="true" @input="setCount">{{
        count
      }}</span>
      <a
        href="#"
        :class="
          count == 100 || (count + 1) * nbsType.cost.base > budget
            ? 'plus inactive'
            : 'plus'
        "
        @mousedown.prevent="setCount(count + 1)"
        >+</a
      >
    </div>

    <div class="warning" v-if="(count + 1) * nbsType.cost.base > budget">
      <img src="/images/alert-triangle.png" />
      Not enough budget to buy more.
    </div>

    <div class="total-cost" v-if="count">
      <div class="text">Total cost:</div>
      <div class="value">
        {{ (nbsType.cost.base * count).toFixed(2) }} {{ currency }}
      </div>
    </div>

    <div v-bind:key="buttonskey">
      <button class="wide" @mousedown.prevent="buy" v-if="!mapModeActive">
        Buy
      </button>
      <button
        class="wide white"
        @mousedown.prevent="cancelNbs"
        v-if="mapModeActive"
      >
        {{ inactiveText }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "GameNbsWidget",
  data: function () {
    return { count: 0, buttonskey: 0 };
  },
  props: {
    nbsType: Object,
    mapModeActive: Boolean,
    budget: Number,
    currency: { type: String, default: "€" },
  },
  watch: {
    mapModeActive() {
      this.buttonskey += 1;
    },
  },
  created() {
    if (!this.nbsType.object.predefined) this.count = this.nbsType.object.count;
  },
  methods: {
    setCount(count) {
      if (typeof count == "object" && count.target) {
        let parsed = parseInt(count.target.innerText) || null;
        if (parsed && !isNaN(parsed)) {
          if (parsed > 100) {
            parsed = 100;
          }
          this.count = parsed;
          this.nbsType.object.count = parsed;
        }
      }

      if (typeof count == "number" && count > 0 && count <= 100) {
        this.count = count;
        this.nbsType.object.count = count;
      }
    },
    buy() {
      this.$emit("buy", this.nbsType, this.count);
    },

    cancelNbs() {
      this.$emit("cancel");
    },
  },
  computed: {
    inactiveText() {
      if (this.count * this.nbsType.cost.base > this.budgetLeft) {
        return "Buy";
      } else {
        return "Stop deploying";
      }
    },
  },
};
</script>
<style>
.nbs-details .description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #2b4d37;
}

.nbs-details .nbs-count,
.nbs-details .total-cost,
.nbs-details .cost {
  margin-bottom: 20px;
}

.nbs-details .cost {
  margin-top: 20px;
}

.nbs-details .total-cost {
  border-top: solid 1px rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  color: #33ac5f;
}
.nbs-details .total-cost .text {
  text-transform: uppercase;
}

.nbs-details .total-cost,
.nbs-details .cost {
  display: flex;
  justify-content: space-between;
}

.nbs-details .image {
  margin: 20px 0;
}

.nbs-details button.inactive {
  background: rgba(43, 77, 55, 0.25);
}
</style>
